body {
    font-family: 'IBM Plex Sans', sans-serif;
    background-color: #f5f5f5;
    color: #333;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.container {
    text-align: center;
    background-color: #fff;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

h2 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
}

pre {
    background-color: #f8f8f8;
    padding: 1rem;
    border-radius: 5px;
    overflow-x: auto;
}

a {
    display: inline-block;
    text-decoration: none;
    color: #333;
    border: 1px solid #333;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
}

a:hover {
    background-color: #333;
    color: #fff;
}
